import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { graphql } from 'gatsby'
import { head } from 'rambdax'
import React from 'react'
import PageLayout from '../components/PageLayout/PageLayout'
import { RichText } from '../components/RichText/RichText'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'
import { getMetaTags } from '../utils/metaTags'

export type TermsAndConditionsData = {
  title: string
  date: string
  content: string
}

export default function LayoutTerms({ pageContext, data }) {
  const terms: TermsAndConditionsData = head(
    data.strapiGQL.termsAndConditions || []
  )
  const content = terms ? terms.content : ''
  return (
    <PageLayout>
      <SubpageHeader
        title={pageContext.title}
        summary={pageContext.subtitle}
        metaTags={getMetaTags(pageContext)}
      />
      <Whitespace type="none">
        <RichText content={content} />
        <Whitespace type="sm">{''}</Whitespace>
      </Whitespace>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query PageQueryTerms {
    strapiGQL {
      termsAndConditions(sort: "date", publicationState: LIVE, limit: 1) {
        title
        date
        content
      }
    }
  }
`
