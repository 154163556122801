import { Container } from '@algbra/ui/components/Grid/Grid'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styles from './ContentBox.module.scss'

export type ContentBoxProps = {
  size?: 'small' | 'normal'
  children: ReactNode
  className?: string
}
export default function ContentBox(props: ContentBoxProps) {
  const { size = 'normal', children, className } = props
  return (
    <Container>
      <div
        className={classNames(
          styles.contentBox,
          styles[`size__${size}`],
          className
        )}
      >
        {children}
      </div>
    </Container>
  )
}
